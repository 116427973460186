import React from "react"
import styled from "styled-components"

import { Text, RoadmapTitle } from "../elements/Text"

const Item = styled.div`
  display: flex;
`

export interface IRoadmapItemProps {
  percentage: string
  title: string
}

const RoadmapItem: React.FC<IRoadmapItemProps> = ({ percentage, title }) => {
  return (
    <Item>
      <RoadmapTitle color="#BB1E43">{percentage}</RoadmapTitle>
      <RoadmapTitle color="#FFF">{title}</RoadmapTitle>
    </Item>
  )
}

export default RoadmapItem

import { useState } from "react"
import { toast } from "react-toastify"

export const useCardano = () => {
  const [network, setNetwork] = useState<number | null>(null)
  const [balance, setBalance] = useState<string>("")
  const [address, setAddress] = useState<string>("")
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const connectWalletAndGetInfo = async () => {
    if (!window.cardano) {
      toast.error("Install Nami browser extension", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    } else {
      setLoading(true)
      await window.cardano.nami.enable()

      setNetwork(await window.cardano.getNetworkId())
      setBalance(await window.cardano.getBalance())
      setAddress(await window.cardano.getUsedAddresses())
      setIsEnabled(true)
      setLoading(false)
    }
  }

  return { network, balance, address, isEnabled, connectWalletAndGetInfo, loading }
}

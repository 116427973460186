import styled from "styled-components"

export const Text = styled.p<{ margin?: string; align?: string }>`
  font-size: ${(props) => props.theme.font_size_secondary};
  color: ${(props) => props.theme.text_color};
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.align};
  line-height: 2rem;

  @media only screen and (max-width: 500px) {
    margin-right: 0;
    margin-left: 0;
  }
`

export const Title = styled.h1`
  font-size: ${(props) => props.theme.font_size_title};
  color: ${(props) => props.theme.text_color};
  font-weight: ${(props) => props.theme.font_weight_primary};
  padding: 10px 0;
  text-align: center;
`

export const FeatureTitle = styled.h2`
  font-size: ${(props) => props.theme.font_size_feature_title};
  color: ${(props) => props.theme.text_color};
  font-weight: ${(props) => props.theme.font_weight_primary};
  margin: 25px 0 10px 0;
  text-align: center;
`

export const RoadmapTitle = styled.h6<{ color?: string }>`
  font-size: ${(props) => props.theme.font_size_feature_title};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.theme.font_weight_primary};
  margin: 15px 35px 15px 0;
`

export const CollapseTitle = styled.h2`
  font-size: ${(props) => props.theme.font_size_collapse};
  color: ${(props) => props.theme.text_color};
  font-weight: ${(props) => props.theme.font_weight_primary};
`

export const NavTitle = styled.h1`
  font-size: ${(props) => props.theme.font_size_secondary};
  color: ${(props) => props.theme.text_color};
  padding: 0 15px;

  &:hover {
    color: ${(props) => props.theme.color_brand};
    transition: ${(props) => props.theme.transition_primary};
  }
`

import React from "react"
import styled from "styled-components"
import Feature, { IFeatureProps } from "../elements/Feature"

import { Title } from "../elements/Text"

const List = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`

const Features: React.FC = () => {
  const featuresList = [
    {
      img: "../../../static/logo.png",
      title: "NFTs",
      content:
        "The NFT collection features 5000 criminals that appear in our MMORPG online game. The entire game is set in (working title) The City of Crime, and the characters follow this theme - they have different backstories, names, stats,..."
    },
    {
      img: "/static/placeholder.jpeg",
      title: "$WNTD Token",
      content:
        "Our main utility is the aforementioned game, but that's not all! Another important utility is our own $WNTD token, which you can earn and spend in-game or trade freely on the market. Another key utility is our own marketplace, which allows us to provide our NFT owners with high security and functionality when selling and buying NFTs. And last but not least, the whole community, that's our key utility! We're not just building a game, a token and a marketplace here. We are building that community that can hold and grow together."
    },

    {
      img: "../../../static/placeholder.jpeg",
      title: "Wanted Game",
      content:
        "The whole game takes place in (working title) The City of Crime. It's a town where no one is a stranger to dirty work and there's actually always something illegal going on, from purse snatching and car theft to pot growing and gang shootouts. Where's your place? It's all up to you! Do you choose to go the hardened criminal route and kill innocent citizens, or do you sell your weed in peace? Again, it's all up to you!."
    }
  ]
  return (
    <div id="about">
      <Title>Wanted | Dead or Alive</Title>
      <List>
        {featuresList.map((item: IFeatureProps, i: number) => (
          <Feature key={i} img={item.img} title={item.title} content={item.content} />
        ))}
      </List>
    </div>
  )
}

export default Features

import React, { ReactElement } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Btn = styled.button<{ bg?: string }>`
  font-size: ${(props) => props.theme.font_size_secondary};
  font-weight: 600;
  border-radius: 50px;
  color: #fff;
  padding: 17px 30px;
  background-color: ${(props) => props.bg};
  border: 2px solid #d1d1d1;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    animation-name: floating;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, -7px);
    }
    100% {
      transform: translate(0, 0px);
    }
  }
`

interface IButtonProps {
  content: string | ReactElement
  bg: string
  to?: string
  type?: string
  onClick?: () => void
  icon?: ReactElement
}

const Button: React.FC<IButtonProps> = ({ content, to, type, onClick, icon, bg }) => {
  return (
    <Link to={to} target="_blank">
      <Btn {...type} bg={bg} onClick={onClick}>
        {content}
        {icon}
      </Btn>
    </Link>
  )
}

export default Button

import React from "react"
import styled from "styled-components"

import { FaDiscord, FaInstagram, FaTwitter } from "react-icons/fa"

import Button from "../elements/Button"
import { Title, Text } from "../elements/Text"

const Wrapper = styled.div`
  padding: 40px 0;
  margin-bottom: 20px;
`

const Div = styled.div`
  margin-left: 10px;
`

const DivBtn = styled.div`
  margin-right: 20px;

  > a {
    text-decoration: none;
  }

  @media only screen and (max-width: 1000px) {
    margin: 0 0 20px 0;
  }
`

const Copy = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;

    > p {
      text-align: center;
      margin-top: 10px;
    }
  }
`

const Main = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  margin-top: 100px;

  > a {
    text-decoration: none;
  }
`

const FooterRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 120px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  > a {
    text-decoration: none;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    margin-top: 20px;
  }
`

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Main>
        <Title>Join Us</Title>
        <Text margin="40px 0" align="center">
          Join our Discord to be a part of Wanted Gang in the metaverse.
        </Text>
        <Button
          content="Join us on Discord"
          icon={
            <Div>
              <FaDiscord size={24} />
            </Div>
          }
          to="https://discord.gg/wantedada"
          bg="#7289da"
        />
      </Main>
      <FooterRow>
        <Copy>
          <img src="/logo.png" alt="Logo" width={70} height={70} />
          <Text margin="0 0 0 10px">Copyright © 2022 Wanted. All rights reserved.</Text>
        </Copy>
        <ButtonsWrapper>
          <DivBtn>
            <Button
              content="Instagram"
              to="https://www.instagram.com/wantedada/"
              icon={
                <Div>
                  <FaInstagram size={24} />
                </Div>
              }
              bg="#e95950"
            />
          </DivBtn>
          <Button
            content="Twitter"
            to="https://twitter.com/wantedada"
            icon={
              <Div>
                <FaTwitter size={24} />
              </Div>
            }
            bg="#1DA1F2"
          />
        </ButtonsWrapper>
      </FooterRow>
    </Wrapper>
  )
}

export default Footer

import React from "react"
import Default from "../components/layouts/Default"
import Home from "../components/templates/Home"

const HomePage: React.FC = () => {
  return (
    <Default>
      <Home />
    </Default>
  )
}

export default HomePage

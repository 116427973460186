import React from "react"
import styled from "styled-components"
import { toast } from "react-toastify"

import DropdownItem from "../elements/DropdownItem"
import Loader from "../elements/Loader"

import { useCardano } from "../../hooks/useCardano"

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
`

const DropDown = styled.ul`
  position: absolute;
  background: #382126;
  top: 120px;
  left: 0;
  width: 100%;
  z-index: 500;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  padding: 15px 0;
  padding-left: 30px;
  list-style-type: none;

  > a {
    text-decoration: none;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }

    80% {
      transform: scaleY(1.01);
    }

    100% {
      transform: scaleY(1);
    }
  }
`

interface IDropdownMenuProps {
  onClick: () => void
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({ onClick }) => {
  const { balance, address, isEnabled, connectWalletAndGetInfo, loading } = useCardano()

  const connectWallet = async () => {
    connectWalletAndGetInfo()
  }

  return (
    <>
      <DropDown>
        <DropdownItem onClick={onClick} to="#roadmap" title="Roadmap" />
        <DropdownItem onClick={onClick} to="#about" title="About" />
        <DropdownItem onClick={onClick} to="#faq" title="FAQ" />
        <DropdownItem onClick={onClick} to="#team" title="Team" />
        <DropdownItem
          onClick={() => {
            connectWallet()
          }}
          to="#"
          title={
            loading ? <Loader /> : isEnabled ? `${address[0].slice(0, 6)}...${address[0].slice(-4)} | ${balance} ADA` : "Connect wallet"
          }
        />
      </DropDown>
      <Background onClick={onClick}></Background>
    </>
  )
}

export default DropdownMenu

import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"

import { IoMenu } from "react-icons/io5"

import Button from "../elements/Button"
import { NavTitle } from "../elements/Text"
import Loader from "../elements/Loader"
import DropdownMenu from "../modules/DropdownMenu"

import { useCardano } from "../../hooks/useCardano"

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;

  > a {
    text-decoration: none;
  }
`
const Nav = styled.nav`
  display: flex;
  align-items: center;

  > a {
    text-decoration: none;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LoaderWrapper = styled.div`
  padding: 0 30px;
`

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const { balance, address, isEnabled, connectWalletAndGetInfo, loading } = useCardano()

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

  const connectWallet = async () => {
    connectWalletAndGetInfo()
  }

  return (
    <Wrapper>
      <Main>
        <Link to="#home">
          <img src="/logo.png" alt="Logo" width={70} height={70} />
        </Link>
        {isTabletOrMobile ? (
          <IoMenu color="#FFF" size={50} cursor="pointer" onClick={() => setMenuOpen(!menuOpen)} />
        ) : (
          <>
            <Nav>
              <Link to="#roadmap">
                <NavTitle>Roadmap</NavTitle>
              </Link>
              <Link to="#about">
                <NavTitle>About</NavTitle>
              </Link>
              <Link to="#faq">
                <NavTitle>FAQ</NavTitle>
              </Link>
              <Link to="#team">
                <NavTitle>Team</NavTitle>
              </Link>
            </Nav>
            <Button
              onClick={() => connectWallet()}
              content={
                loading ? (
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                ) : isEnabled ? (
                  `${address[0].slice(0, 6)}...${address[0].slice(-4)}`
                ) : (
                  "Connect wallet"
                )
              }
              bg="#BB1E43"
            />
          </>
        )}
      </Main>
      {menuOpen && <DropdownMenu onClick={() => setMenuOpen(!menuOpen)} />}
    </Wrapper>
  )
}

export default Header

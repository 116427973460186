import React from "react"
import styled from "styled-components"

import { FaDiscord, FaTwitter } from "react-icons/fa"

import Button from "../elements/Button"
import { Text } from "../elements/Text"

const Main = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin: 120px 0;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 900px) {
    margin: 75px 0;
  }
`

const Div = styled.div`
  margin-right: 20px;

  > a {
    text-decoration: none;
  }

  @media only screen and (max-width: 900px) {
    margin-bottom: 20px;
  }
`

const IconWrapper = styled.div`
  margin-left: 10px;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-right: 40px;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  @media only screen and (max-width: 900px) {
    padding-right: 0;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;

  > a {
    text-decoration: none;
  }

  @media only screen and (max-width: 900px) {
    margin-bottom: 50px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const Banner: React.FC = () => {
  return (
    <div id="home">
      <Main>
        <Left>
          <Text margin="0 0 40px 0">
            Wanted: Dead or Alive is a collection of pixel art NFTs featuring a variety of criminals. Each criminal is unique, with a unique
            backstory and set of statistics. Each criminal is granted access to an online game in which they can explore the world in
            campaign mode, complete missions, join gangs, and battle other players.
          </Text>
          <Buttons>
            <Div>
              <Button
                content="Join us on Discord"
                to="https://discord.gg/wantedada"
                icon={
                  <IconWrapper>
                    <FaDiscord size={24} />
                  </IconWrapper>
                }
                bg="#7289da"
              />
            </Div>
            <Button
              content="Follow us on Twitter"
              to="https://twitter.com/wantedada"
              icon={
                <IconWrapper>
                  <FaTwitter size={24} />
                </IconWrapper>
              }
              bg="#1DA1F2"
            />
          </Buttons>
        </Left>
        <img src="/placeholder.png" alt="Wanted" />
      </Main>
      <Text margin="50px 80px 120px 80px" align="center">
        Wanted | Dead or Alive launching soon!
      </Text>
    </div>
  )
}

export default Banner

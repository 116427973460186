import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`    
    *,
    *:before,
    *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }

    *:focus {
    outline: 0;
    }

    html {
    scroll-behavior: smooth;
    }   

    body {
    font-family: "Chakra Petch", sans-serif;
    background-color: #202020;
    background-image: linear-gradient(20deg, #bb1e43 -250%, #ffffff00 50%), linear-gradient(200deg, #bb1e43 -250%, #ffffff00 50%);

    }

    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(187,30,67, 0.8);
        border-radius: 16px;
        height: 12px;
    }

    ::-webkit-scrollbar-track {
        background: rgb(211, 211, 211);
    }
`

export default GlobalStyle

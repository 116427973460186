import React from "react"
import styled from "styled-components"
import useCollapse from "react-collapsed"

import { IoAdd, IoChevronUp } from "react-icons/io5"

import { Text, CollapseTitle } from "../elements/Text"

const Row = styled.div<{ border?: string | null }>`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) => props.border};
  cursor: pointer;
`

const Content = styled.div`
  border-bottom: 1px solid #fff;
`

export interface ICollapsibleItemProps {
  title: string
  content: string
}

const CollapsibleItem: React.FC<ICollapsibleItemProps> = ({ title, content }) => {
  const config = {
    duration: 800
  }

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config)

  return (
    <>
      <Row {...getToggleProps()} border={!isExpanded ? "1px solid #fff" : "1px solid transparent"}>
        <CollapseTitle>{title}</CollapseTitle>
        {isExpanded ? <IoChevronUp color="#BB1E43" size={28} /> : <IoAdd color="#BB1E43" size={28} />}
      </Row>
      <Content {...getCollapseProps()}>
        <Text margin="15px 10px">{content}</Text>
      </Content>
    </>
  )
}

export default CollapsibleItem

import React, { ReactElement } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Item = styled.li`
  font-size: ${(props) => props.theme.font_size_secondary};
  color: ${(props) => props.theme.text_color};
  font-weight: ${(props) => props.theme.font_weight_primary};
  padding: 15px 0 15px 15;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

interface IDropdownItemProps {
  title: string | ReactElement
  to?: string
  onClick: () => void
}

const DropdownItem: React.FC<IDropdownItemProps> = ({ title, to, onClick }) => {
  return (
    <Link to={to} onClick={onClick}>
      <Item>{title}</Item>
    </Link>
  )
}

export default DropdownItem

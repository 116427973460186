import React from "react"
import styled from "styled-components"
import TeamMember, { ITeamMemberProps } from "../elements/TeamMember"

import { Title, Text } from "../elements/Text"

const Members = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

const Team: React.FC = () => {
  const teamMembers = [
    { img: "../../../static/luka.png", name: "Luka Budík", about: "Head of Marketing" },
    { img: "../../../static/adam.png", name: "Adam Schinzel", about: "Head of Engineering" },
    { img: "../../../static/jachym.png", name: "Jáchym Hala", about: "Head of Art" }
  ]

  return (
    <div id="team">
      <Title>Team</Title>
      <Text margin="30px 80px" align="center">
        This project is mostly the work of three young individuals, Adam, Jáchym, and Luka. These three have known each other since the
        sixth grade when they attended primary school together in Brno, Czech Republic.
      </Text>
      <Members>
        {teamMembers.map((member: ITeamMemberProps, i: number) => (
          <TeamMember key={i} img={member.img} name={member.name} about={member.about} />
        ))}
      </Members>
      <Text margin="30px 80px" align="center">
        Special thanks to all the discord mods that are doing an amazing job handling the Wanted | Dead or Alive server. @just_insane
        @zer0.k @kungskell
      </Text>
    </div>
  )
}

export default Team

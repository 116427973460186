import React from "react"
import styled from "styled-components"
import RoadmapCharacter from "../elements/RoadmapCharacter"
import RoadmapItem, { IRoadmapItemProps } from "../elements/RoadmapItem"

import { Title } from "../elements/Text"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 150px;
  margin-top: 50px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

const Items = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-left: 75px;

  @media only screen and (max-width: 1000px) {
    align-items: center;
    margin: 0;
  }
`

const Roadmap: React.FC = () => {
  const roadmapItems = [
    {
      percentage: "0%",
      title: "Example milestone"
    },
    {
      percentage: "10%",
      title: "Example milestone"
    },
    {
      percentage: "20%",
      title: "Example milestone"
    },
    {
      percentage: "30%",
      title: "Example milestone"
    },
    {
      percentage: "40%",
      title: "Example milestone"
    },
    {
      percentage: "60%",
      title: "Example milestone"
    },
    {
      percentage: "90%",
      title: "Example milestone"
    },
    {
      percentage: "100%",
      title: "Example milestone"
    }
  ]
  return (
    <div id="roadmap">
      <Title>Roadmap</Title>
      <Wrapper>
        <RoadmapCharacter />
        <Items>
          {roadmapItems.map((item: IRoadmapItemProps, i: number) => (
            <RoadmapItem key={i} percentage={item.percentage} title={item.title} />
          ))}
        </Items>
      </Wrapper>
    </div>
  )
}

export default Roadmap

import React from "react"
import styled from "styled-components"

import { Text, FeatureTitle } from "../elements/Text"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  border-radius: 24px;
  align-self: center;
  justify-content: center;
  padding: 60px 50px;
  max-width: 400px;
  background-image: linear-gradient(180deg, #bb1e43 -150%, #ffffff00 50%);
`

export interface ITeamMemberProps {
  img: string
  name: string
  about: string
}

const RoadmapCharacter: React.FC = () => {
  return (
    <Card>
      <img src="/placeholder.png" alt="Luka" />
      <FeatureTitle>Luka Budík</FeatureTitle>
      <Text align="center">
        We are proud to built one of the most amazing and welcoming communities in the entire cardano ecosystem. Every Wanted holder has a
        voice in this project and will be able to steer the project with their input. Don't hesitate, join Wanted | Dead or Alive and let
        your voice be heard!
      </Text>
    </Card>
  )
}

export default RoadmapCharacter

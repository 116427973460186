export default {
  color_brand: "#BB1E43",
  color_opensea: "#2663FF",
  color_discord: "#FA0369",
  color_purple: "#9B51E0",
  text_color: "#FFF",
  font_size_primary: "1rem",
  font_size_secondary: "1.25rem",
  font_size_collapse: "1.6rem",
  font_size_title: "2.6rem",
  font_size_feature_title: "1.5rem",
  font_weight_primary: "500",
  font_weight_secondary: "500",
  transition_primary: "all 0.15s linear"
}

import React from "react"
import Banner from "../modules/Banner"
import FAQ from "../modules/FAQ"
import About from "../modules/About"
import Roadmap from "../modules/Roadmap"
import Team from "../modules/Team"

const Home: React.FC = () => {
  return (
    <>
      <Banner />
      <Roadmap />
      <About />
      <FAQ />
      <Team />
    </>
  )
}

export default Home

import React from "react"
import styled from "styled-components"
import CollapsibleItem, { ICollapsibleItemProps } from "../elements/CollapsibleItem"

import { Title } from "../elements/Text"

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 150px;
`

const FAQ: React.FC = () => {
  const FAQ = [
    {
      title: "Test questions for Wanted",
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean vel massa quis mauris vehicula lacinia. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
      title: "Test questions for Wanted",
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean vel massa quis mauris vehicula lacinia. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
      title: "Test questions for Wanted",
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean vel massa quis mauris vehicula lacinia. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
      title: "Test questions for Wanted",
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean vel massa quis mauris vehicula lacinia. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
      title: "Test questions for Wanted",
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean vel massa quis mauris vehicula lacinia. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    }
  ]

  return (
    <div id="faq">
      <Title>FAQ</Title>
      <List>
        {FAQ.map((row: ICollapsibleItemProps, i: number) => (
          <CollapsibleItem key={i} title={row.title} content={row.content} />
        ))}
      </List>
    </div>
  )
}

export default FAQ

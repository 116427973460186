import React from "react"
import styled from "styled-components"

import { Text, FeatureTitle } from "../elements/Text"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  border-radius: 24px;
  align-self: center;
  justify-content: center;
  padding: 60px 50px;
  background-image: linear-gradient(180deg, #bb1e43 -150%, #ffffff00 50%);

  @media only screen and (max-width: 1100px) {
    margin: 0;
  }
`

export interface ITeamMemberProps {
  img: string
  name: string
  about: string
}

const TeamMember: React.FC<ITeamMemberProps> = ({ img, name, about }) => {
  return (
    <Card>
      <img src="/placeholder.png" alt={name} width={250} height={250} />
      <FeatureTitle>{name}</FeatureTitle>
      <Text align="center">{about}</Text>
    </Card>
  )
}

export default TeamMember

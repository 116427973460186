import React from "react"
import styled from "styled-components"

import { Text, FeatureTitle } from "../elements/Text"

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 20px;
  width: 500px;

  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 500px;
    padding: 0 30px;
  }
`

export interface IFeatureProps {
  img: string
  title: string
  content: string
}

const Feature: React.FC<IFeatureProps> = ({ img, title, content }) => {
  return (
    <Item>
      <img src="/placeholder.png" alt={title} />
      <FeatureTitle>{title}</FeatureTitle>
      <Text>{content}</Text>
    </Item>
  )
}

export default Feature

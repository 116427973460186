import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

import GlobalStyle from "../../../shared/Global"
import theme from "../../../config/theme"

import Header from "../modules/Header"
import Footer from "../modules/Footer"

const Layout = styled.div`
  width: 100%;
`

const Container = styled.div`
  max-width: 1300px;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  margin-left: auto;
  margin-right: auto;
`

interface Props {
  children: React.ReactNode
}

const Default: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta name="viewport" content="width=device-width initial-scale=1" />
        <title>Wanted | Dead or Alive</title>
        <meta
          name="description"
          content="A collection of pixel art NFTs featuring a variety of criminals. Each criminal is unique, with a unique backstory and set of statistics."
        />
        <link rel="icon" href="/favicon.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap" rel="stylesheet"></link>

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wantedada.com/" />
        <meta property="og:title" content="Wanted | Dead or Alive" />
        <meta
          property="og:description"
          content="A collection of pixel art NFTs featuring a variety of criminals. Each criminal is unique, with a unique backstory and set of statistics."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://wantedada.com/" />
        <meta property="twitter:title" content="Wanted | Dead or Alive" />
        <meta
          property="twitter:description"
          content="A collection of pixel art NFTs featuring a variety of criminals. Each criminal is unique, with a unique backstory and set of statistics."
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Layout>
          <Container>
            <ToastContainer />
            <Header />
            {children}
            <Footer />
          </Container>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default Default
